import React, { useEffect } from 'react';
import { Button, Row, Col } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap-icons/font/bootstrap-icons.css';
import Tooltip from 'bootstrap/js/dist/tooltip';
import NumericInput from '../NumericInput';
import IntegerInput from '../IntegerInput';
import CardsEquipamentos from '../CardsEquipamentos';

const IluminationExternaInfo = ({ formData, handleChange, handlePreviousSection, handleNextSection }) => {
  useEffect(() => {
    const tooltips = document.querySelectorAll('[data-toggle="tooltip"]');
    tooltips.forEach((tooltip) => new Tooltip(tooltip));
  }, []);

  const iluminacionExterna = [
    { label: 'Descarga de alta intensidad (HID)', id: 'descarga_de_alta_intensidad_hid', name: 'descarga_de_alta_intensidad_hid', img: '/img/form/ilumination/descarga_alta_intencidade_hid.png', label: 'Ejemplo De Poste Con Lámpara De Descarga De Vapor Hid', tooltip: ' Las lámparas de descarga de vapor son un tipo de iluminación en la que la luz se genera mediante la ionización de vapor de un metal dentro de un tubo de descarga. Generalmente, se utilizan para la iluminación exterior.'},
    { label: 'Lámparas de vapor de mercurio (MV)',  id: 'lamparas_de_vapor_de_mercurio_mv', name: 'lamparas_de_vapor_de_mercurio_mv', img: '/img/form/ilumination/vapor_mercurio.png'},
    { label: 'Lámparas de vapor de sodio de alta presión (HPS)',  id: 'lamparas_de_vapor_de_sodio_de_alta_presion_hps', name: 'lamparas_de_vapor_de_sodio_de_alta_presion_hps', img: "/img/form/ilumination/vapor_sodio_hps.png"},
    { label: 'Lámparas de vapor de sodio de baja presión (LPS)',  id: 'lamparas_de_vapor_de_sodio_de_baja_presion_lps', name: 'lamparas_de_vapor_de_sodio_de_baja_presion_lps', img: "/img/form/ilumination/vapor_sodio_lps.png"},
    { label: 'Lámparas de halogenuros metálicos (MH)',  id: 'lamparas_de_halogenuros_metalicos_mh', name: 'lamparas_de_halogenuros_metalicos_mh', img: "/img/form/ilumination/halougenuros_mh.png"},
    { label: 'Lámparas mixtas',  id: 'lamparas_mixtas', name: 'lamparas_mixtas', img: "/img/form/ilumination/lampadas_mistas.png"},
    { label: 'Poste de luz LED',  id: 'poste_de_luz_led', name: 'poste_de_luz_led', img: "/img/form/ilumination/poste_luz_led.png"},
    { label: "Reflectores LED",  id: 'reflectores_led', name: 'reflectores_led', img: "/img/form/ilumination/refletores_led.png"},

    // { tipo: 'Gabinete', img: 'https://paper-attachments.dropboxusercontent.com/s_2E186D6F98631C03C390D5BE65DE0911E9130DB71B0853F933AC852F7D8BBC00_1720210265273_image.png' },
    // { tipo: 'Downligth' },
    // { tipo: 'Punta de poste', img: 'https://paper-attachments.dropboxusercontent.com/s_2E186D6F98631C03C390D5BE65DE0911E9130DB71B0853F933AC852F7D8BBC00_1720210265273_image.png' }
  ]

  return (
    <section className="form-section active" id="section-iluminacion-externa">

      <h2 className='mt-4'>Iluminación Externa</h2>

      <label>Canchas deportivas al aire libre, jardines, áreas de recreación al aire libre, estacionamientos, etc...</label>

      <img src='/img/form/ilumination/recreio.webp' alt='Exemplo Painéis Solares' className="w-100 image-500"/>

      <label className='img-label'>Ejemplo de iluminación externa</label>

      <CardsEquipamentos formData={formData} handleChange={handleChange} equipamentos={iluminacionExterna} />

      {/* <div className="iluminacion-automated-controls">
        <label>¿Existen controles automatizados?</label>
        <img
          src={'/img/form/ilumination/controles_automatizados.png'}
          alt={`Ejemplo de Controle Automatizado`}
          className="img-max-width" />
        <div className="form-check">
          <input
            className="form-check-input iluminacion-info"
            type="radio"
            name="controles_automatizados"
            id="controles_si"
            value="sí"
            checked={formData.controles_automatizados === 'sí'}
            onChange={handleChange}
            
          />
          <label className="form-check-label" htmlFor="controles_si">Sí</label>
        </div>
        <div className="form-check mb-3">
          <input
            className="form-check-input iluminacion-info"
            type="radio"
            name="controles_automatizados"
            id="controles_no"
            value="no"
            checked={formData.controles_automatizados === 'no'}
            onChange={handleChange}
            
          />
          <label className="form-check-label" htmlFor="controles_no">No</label>
        </div>
      </div> */}

      <div className="navigation-buttons">
        <Button className="btn btn-info mt-2 text-white" variant="secondary" onClick={handlePreviousSection}>
          Anterior
        </Button>
        <Button className="btn btn-info mt-2 text-white" variant="primary" onClick={handleNextSection}>
          Próximo
        </Button>
      </div>
    </section>
  );
};

export default IluminationExternaInfo;
