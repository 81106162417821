// Home.js
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Container, Form, Button } from 'react-bootstrap';
import PopoverComponent from './components/PopoverComponent';
import axios from 'axios';
import './css/home.css';

const Home = ( props ) => {

  const [formData, setFormData] = useState({ codigo: '' });
  const navigate = useNavigate();

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.id]: e.target.value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    navigate(`/form/${formData.codigo}`);
  
    // const postUrl = `${props.BACKEND_URL}/api`
    // console.log(postUrl)

    // const data = new FormData();

    // data.append('codigo', formData['codigo']);

    // axios.post(postUrl, data)
    // .then(response => {
    //     console.log('Form submitted successfully:', response);
    //     navigate(`/form/${formData.codigo}`);
    // })
    // .catch(error => {
    //     console.error('Error submitting form:', error);
    //     alert("Ocorreu um erro no envio de código")
    // });
  };

  return (
    <div id="home">
      <header className="mb-4">
        <Container className="d-flex justify-content-between align-items-center py-3">
          <img src="/img/BID_espanhol.png" alt="Logo BID" width="250" />
          <img src="/img/logo_mitsidi.png" width="250" alt="Logo Mitsidi" className="logo-mitsidi" />
        </Container>
      </header>
      <Container className="d-flex justify-content-center align-items-center">
        <div className="form-container">
          <h1 className="text-center mb-4">Bienvenido</h1>
          <Form onSubmit={handleSubmit}>
            <Form.Group controlId="codigo" className="form_formulario">
              <Form.Label>
                Ingrese su Clave de Centro de Trabajo (CCT) para acceder al formulario
                <PopoverComponent text="La contraseña de acceso del Estado es un código compuesto por una secuencia de 6 dígitos, proporcionado por los gestores de las escuelas."/>
              </Form.Label>
              <Form.Control
                type="text"
                required
                value={formData.codigo}
                onChange={handleChange}
              />
            </Form.Group>
            <Button className="button_formulario" variant="primary" type="submit" block>
              Acceder al Formulario
            </Button>
          </Form>
        </div>
      </Container>
      <footer className="mt-auto">
        <Container>
          <p>&copy; 2024 Mitsidi y BID</p>
        </Container>
      </footer>
    </div>
  );
};

export default Home;
