import React, { useEffect } from 'react';
import { Button, Row, Col } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap-icons/font/bootstrap-icons.css';
import Tooltip from 'bootstrap/js/dist/tooltip';
import NumericInput from '../NumericInput';
import IntegerInput from '../IntegerInput';
// import CardsEquipamentos from '../CardsEquipamentos';
import CardsArEquipamentos from './ArCondicionado/EquipamentosArCondicionado';

const ArCondicionados = ({ formData, setFormData, handleChange, handlePreviousSection, handleNextSection }) => {
  useEffect(() => {
    const tooltips = document.querySelectorAll('[data-toggle="tooltip"]');
    tooltips.forEach((tooltip) => new Tooltip(tooltip));
  }, []);

  const equipamentsAirConditioning = [
    // { tipo: 'Ventilador', img: '/img/form/air_conditioning/ventilador.png' },
    {
      label: 'Split',
      id:'split',
      name:'split',
      img: '/img/form/air_conditioning/split.png',
      tooltip: 'Aire acondicionado Split inverter: La unidad exterior tiene un compresor que funciona a velocidad variable para mantener la temperatura deseada. Esto los hace más eficientes energéticamente y más silenciosos que los aires acondicionados Split tradicionales. La unidad interior también tiene un ventilador que hace circular el aire sobre los serpentines de enfriamiento.',
      potencia_btu: true,
      capacidad_de_enfriamiento: true,
      reee: true,
      inverter: true,
    },
    { 
      label: 'Ventana',
      id:'ventana',
      name:'ventana',
      img: '/img/form/air_conditioning/vantana.png',
      tooltip: 'Equipo de aire acondicionado compacto que tiene el compresor, la condensadora y la evaporadora en el mismo gabinete.',
      potencia_btu: true,
      capacidad_de_enfriamiento: true,
      reee: true,
    },
    { 
      label: 'Enfriador evaporativo portatil',
      id:'esfriador_evaporativo_portatil',
      name:'esfriador_evaporativo_portatil',
      img: '/img/form/air_conditioning/esfriador_portatil.png',
      tooltip: 'Un enfriador evaporativo portátil refresca el ambiente mediante la evaporación de agua, creando una brisa fresca y húmeda.',
      potencia_btu: true,
      capacidad_de_enfriamiento: true,
      reee: true,
    },
    { 
      label: 'Paquete',
      id:'paquete',
      name:'paquete',
      img: '/img/form/air_conditioning/paquete.png',
      tooltip: 'Los paquetes de aire acondicionado, también conocidos como Unit Package o Rooftop, son sistemas compactos que integran todos los componentes de climatización en una sola unidad.',
      potencia_btu: true,
      capacidad_de_enfriamiento: true,
      reee: true,
    },
    // { tipo: 'Cooler', img: '', tooltip: '' }
  ]

  useEffect(() => {
    const tooltips = document.querySelectorAll('[data-toggle="tooltip"]');
    tooltips.forEach((tooltip) => new Tooltip(tooltip));
  }, []);

  return (
    <section className="form-section active" id="section-ar-condicionados">
      <h2>Calefacción, ventilación y aire acondicionado (HVAC)</h2>

      <p>
        Para completar los campos a continuación,
        es importante entender cómo leer la información que se encuentra en la etiqueta amarilla de los equipos.
        La etiqueta amarilla proporciona datos esenciales sobre el consumo de energía y la eficiencia del equipo.
        A continuación, se presenta un ejemplo de una etiqueta amarilla con instrucciones sobre el significado de cada campo
      </p>

      <span
        data-toggle="tooltip"
        data-placement="top"
        title="Si la etiqueta no está disponible no es necesario rellenar los campos de potencia, capacidad y eficiencia energética. - Adicionar funcionalidade para acrescentar diferentes tipos de Ar Condicionado."
      >
        <i className="bi bi-info-circle-fill custom-tooltip"></i>
      </span>

      <img
      style={{ width: '100%' }}
      src='/img/form/air_conditioning/acodiconador_de_aire_tipo_dividido.png'
      alt={`Ejemplo de acondicionador de aire tipo dividido`}
      />

      {/* <img
      style={{ width: '100%', marginTop: '2em' }}
      src='/img/form/air_conditioning/etiqueta_ee.png'
      alt={`Ejemplo de acondicionador de aire tipo dividido`}
      /> */}

      <p className='text-img'>Contenido de una etiqueta de eficiencia energética</p>

      <label htmlFor="horas_dias_dispositivos">
        En los meses más cálidos, en promedio, ¿cuántas horas al día están encendidos los dispositivos?
      </label>
      <NumericInput
        id="horas_dias_dispositivos"
        name="horas_dias_dispositivos"
        value={formData.horas_dias_dispositivos}
        onChange={handleChange}
      />

      <div style={{ marginTop: '2em' }}></div> 

      {/* (Cooler, Ventana, Minisplit, Paquete) Calefacción, ventilación y aire acondicionado (HVAC) */}

      {/* <CardsEquipamentos formData={formData} handleChange={handleChange} equipamentos={equipamentsAirConditioning} /> */}
      <CardsArEquipamentos formData={formData} setFormData={setFormData} handleChange={handleChange} equipamentos={equipamentsAirConditioning} />

      <label htmlFor="edad_equipos" style={{ marginTop: '1.2em' }}>Edad promedio de los equipos:</label>
      <select
        id="edad_equipos"
        name="edad_equipos"
        className="form-control"
        value={formData['edad_equipos']}
        onChange={handleChange}
      >
        <option value="menos-5-anos">menos de 5 años</option>
        <option value="5-10-anos">entre 5 y 10 años</option>
        <option value="mas-10-anos">más de 10 años</option>
      </select>

      <div style={{ marginTop: '1.5em' }}>
        <Button className="btn btn-info mt-2 text-white" variant="secondary" onClick={handlePreviousSection}>
          Anterior
        </Button>
        {/* <Button className="btn btn-info mt-2 text-white" variant="primary" onClick={handleNextSection}>
          Próximo
        </Button> */}
        <Button className="btn btn-info mt-2 text-white" variant="primary" type="submit" block>
          <strong>Enviar</strong>
        </Button>
      </div>
    </section>
  );
};

export default ArCondicionados;
