import React from 'react';
import { NumericFormat } from 'react-number-format';

const NumericInput = ( props ) => {
  const handleChange = (values) => {
    const { floatValue } = values;
    props.onChange({ target: { name: props.name, value: floatValue } });
  };

  return (
    <NumericFormat
      id={props.id}
      name={props.name}
      value={props.value}
      thousandSeparator={true}
      decimalScale={2}
      fixedDecimalScale={true}
      placeholder="Ex: 1,000.00"
      className={ props.className }
      onValueChange={handleChange}
      {...(props.required && { required: true })}
    />
  );
};

export default NumericInput;
