import React, { useEffect } from 'react';
import { Button } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap-icons/font/bootstrap-icons.css';
import Tooltip from 'bootstrap/js/dist/tooltip';

const GeneralInfo = ({ formData, handleChange, handleFileChange, handleNextSection }) => {
  useEffect(() => {
    const tooltips = document.querySelectorAll('[data-toggle="tooltip"]');
    tooltips.forEach((tooltip) => new Tooltip(tooltip));
  }, []);

  return (
    <section className="form-section active" id="section-general">
      <h2>Informaciones generales</h2>
      <label htmlFor="cct">
        Clave de Centro de Trabajo (CCT) *:
        <span
          data-toggle="tooltip"
          data-placement="top"
          title="La Clave de Centro de Trabajo es un código compuesto por 10 dígitos que sigue el siguiente formato: Número-Número-Letra-Letra-Letra-Número-Número-Número-Número-Letra. Ejemplo: 11XXX1111X"
        >
          <i className="bi bi-info-circle-fill custom-tooltip"></i>
        </span>
      </label>
      <input
        type="text"
        id="cct"
        name="cct"
        className="form-control general-info"
        value={formData.cct}
        onChange={handleChange}
        required
      />

      <label htmlFor="cct">
        RPU:
      </label>
      <input
        type="text"
        id="rpu"
        name="rpu"
        className="form-control general-info"
        value={formData.rpu}
        onChange={handleChange}
      />


      {/* <label htmlFor="cct">
        CCTs Associados*:
        <span
          data-toggle="tooltip"
          data-placement="top"
          title="La Clave de Centro de Trabajo es un código compuesto por 10 dígitos que sigue el siguiente formato: Número-Número-Letra-Letra-Letra-Número-Número-Número-Número-Letra. Ejemplo: 11XXX1111X"
        >
          <i className="bi bi-info-circle-fill custom-tooltip"></i>
        </span>
      </label>
      <input
        type="text"
        id="cct"
        name="cct"
        className="form-control general-info"
        value={formData.ccts_associados}
        onChange={handleChange}
        disabled
      /> */}

      <label htmlFor="nombre_encuestado">
        Nombre de la persona que respondió el cuestionario:
      </label>
      <input
        type="text"
        id="nombre_encuestado"
        name="nombre_encuestado"
        className="form-control general-info"
        value={formData.nombre_encuestado}
        onChange={handleChange}
      />

      <label htmlFor="cargo_encuestado">
        Cargo de la persona que respondió el cuestionario:
      </label>
      <input
        type="text"
        id="cargo_encuestado"
        name="cargo_encuestado"
        className="form-control general-info"
        value={formData.cargo_encuestado}
        onChange={handleChange}
      />

      <label htmlFor="correo_encuestado">
        Correo electronico de la persona que respondió el cuestionario:
      </label>
      <input
        type="email"
        id="correo_encuestado"
        name="correo_encuestado"
        className="form-control general-info"
        value={formData.correo_encuestado}
        onChange={handleChange}
      />

      <label htmlFor="nombre_director">
        Nombre del director o responsable de la escuela:
      </label>
      <input
        type="text"
        id="nombre_director"
        name="nombre_director"
        className="form-control general-info"
        value={formData.nombre_director}
        onChange={handleChange}
      />

      <label htmlFor="correo_director">
        Correo electrónico del director o responsable de la escuela:
      </label>
      <input
        type="email"
        id="correo_director"
        name="correo_director"
        className="form-control general-info"
        value={formData.correo_director}
        onChange={handleChange}
      />

      <label htmlFor="nombre_inmueble">
        Nombre del inmueble / escuela *:
      </label>
      <input
        type="text"
        id="nombre_inmueble"
        name="nombre_inmueble"
        className="form-control general-info"
        value={formData.nombre_inmueble}
        onChange={handleChange}
        required
      />

      <label htmlFor="direccion">
        Dirección *:
      </label>
      <input
        type="text"
        id="direccion"
        name="direccion"
        className="form-control general-info"
        value={formData.direccion}
        onChange={handleChange}
        required
      />

      <label htmlFor="codigo_postal">
        Código postal del inmueble *:
      </label>
      <input
        type="text"
        id="codigo_postal"
        name="codigo_postal"
        className="form-control general-info"
        value={formData.codigo_postal}
        onChange={handleChange}
        required
      />

      <label htmlFor="municipio">
        Nombre del Município del inmueble *:
      </label>
      <input
        type="text"
        id="municipio"
        name="municipio"
        className="form-control general-info"
        value={formData.municipio}
        onChange={handleChange}
        required
      />

      <label htmlFor="estado">
        Estado *:
      </label>
      <select
        id="estado"
        name="estado"
        className="form-control general-info"
        value={formData.estado}
        onChange={handleChange}
        required
      >
        <option value='' hidden></option>
        <option value="02 Baja California">02 Baja California</option>
        <option value="03 Baja California Sur">03 Baja California Sur</option>
        <option value="08 Chihuahua">08 Chihuahua</option>
        <option value="05 Coahuila">05 Coahuila</option>
        <option value="10 Durango">10 Durango</option>
        <option value="19 Nuevo​​Nombre del Município del inmueble lipas">28 Tamaulipas</option>
        <option value="25 Sinaloa">25 Sinaloa</option>
        <option value="26 Sonora">26 Sonora</option>
        <option value="24 San Luis Potosí">24 San Luis Potosí</option>
      </select>

      <label htmlFor="latitud">
        Latitud del inmueble:
        <span
          data-toggle="tooltip"
          data-placement="top"
          title="Para obtener las coordenadas, basta con acceder a Google Maps, encontrar el lugar deseado y hacer clic con el botón derecho del ratón directamente en el mapa, en el punto deseado, y seleccionar '¿Qué hay aquí?' en el menú que aparece. Las coordenadas se mostrarán en la parte inferior del mapa."
        >
          <i className="bi bi-info-circle-fill custom-tooltip"></i>
        </span>
      </label>
      <input
        type="text"
        id="latitud"
        name="latitud"
        className="form-control general-info"
        value={formData.latitud}
        onChange={handleChange}
      />

      <label htmlFor="longitud">
        Longitud del inmueble:
      </label>
      <input
        type="text"
        id="longitud"
        name="longitud"
        className="form-control general-info"
        value={formData.longitud}
        onChange={handleChange}
      />

      <label>
        ¿Hay condiciones que garantizan la seguridad de un sistema de paneles fotovoltaicos?
        <span
          data-toggle="tooltip"
          data-placement="top"
          title="Si el lugar cuenta con condiciones para mantener el sistema fotovoltaico y las conexiones eléctricas fuera del alcance de los niños."
        >
          <i className="bi bi-info-circle-fill custom-tooltip"></i>
        </span>
        <span
          data-toggle="tooltip"
          data-placement="top"
          title="Posee medidas de seguridad para evitar furtos, como cercas de protección, cámaras de vigilancia y sistemas de alarma."
        >
          <i className="bi bi-info-circle-fill custom-tooltip"></i>
        </span>
      </label>
      <div className="form-check">
        <input
          className="form-check-input"
          type="radio"
          name="seguridad"
          id="seguridad_si"
          value="sí"
          checked={formData.seguridad === 'sí'}
          onChange={handleChange}
        />
        <label className="form-check-label" htmlFor="seguridad_si">Sí</label>
      </div>
      <div className="form-check">
        <input
          className="form-check-input"
          type="radio"
          name="seguridad"
          id="seguridad_no"
          value="no"
          checked={formData.seguridad === 'no'}
          onChange={handleChange}
        />
        <label className="form-check-label" htmlFor="seguridad_no">No</label>
      </div>

      <label>¿La escuela cuenta con servicios de internet?</label>
      <div className="form-check">
        <input
          className="form-check-input"
          type="radio"
          name="internet"
          id="internet_si"
          value="sí"
          checked={formData.internet === 'sí'}
          onChange={handleChange}
        />
        <label className="form-check-label" htmlFor="seguridad_si">Sí</label>
      </div>
      <div className="form-check">
        <input
          className="form-check-input"
          type="radio"
          name="internet"
          id="internet_no"
          value="no"
          checked={formData.internet === 'no'}
          onChange={handleChange}
        />
        <label className="form-check-label" htmlFor="seguridad_no">No</label>
      </div>

      {/* <label htmlFor="fotos">Fotos (Áreas externas, internas y techo):</label>
      <input
        type="file"
        id="fotos"
        name="fotos"
        className="form-control-file"
        onChange={handleFileChange}
        multiple
      /> */}

      <Button className="btn btn-info mt-2 text-white" variant="primary" type="button" onClick={handleNextSection}>
        <strong>Próximo</strong>
      </Button>
    </section>
  );
};

export default GeneralInfo;

