import React, { useState } from 'react';

const FileInput = ({ formData, handleFileChange, name, id, label, buttonLabel }) => {
    const [filename, setFilename] = useState(formData[name] ? formData[name]: '');

    const handleFileChangeFinal = (event) => {
        handleFileChange(event)

        const file = event.target.files[0];
        if (file) {
            setFilename(file.name);
        } else {
            setFilename('');
        }
    };

    return (
        <div style={{ marginBottom: '1.6em' }}>
            <label htmlFor={id}>{label}:</label>
            <br /> {/* Line break added here */}
            <input
                type="file"
                id={id}
                name={name}
                style={{ display: 'none' }}
                onChange={handleFileChangeFinal}
            />
            <label htmlFor={id} className="custom-file-label w-100">
                {filename ? `Arquivo Selecionado: ${filename}` : 'Selecione um arquivo'}
            </label>
            {buttonLabel && (
                <button
                type="button"
                onClick={() => document.getElementById(label).click()}
                className="btn btn-primary"
                >
                    {filename ? `Change ${buttonLabel}` : `Choose ${buttonLabel}`}
                </button>
            )}
            
        </div>
    );
};

export default FileInput;