import React, { useEffect, useState } from 'react';
import { Button } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap-icons/font/bootstrap-icons.css';
import Tooltip from 'bootstrap/js/dist/tooltip';
import NumericInput from '../NumericInput';
import IntegerInput from '../IntegerInput';
import FileInput from '../FileInput';

import Months from './/Energia/Months.js'

const DadosMensais = ({formData, setFormData, handleChange}) => {
  const months = [
    'Enero', 'Febrero', 'Marzo', 'Abril', 'Mayo', 'Junio', 'Julio', 'Agosto', 'Septiembre', 'Octubre', 'Noviembre', 'Diciembre'
  ];

  const startYear = 2024;
  const endYear = 2021;
  const years = Array.from({ length: startYear - endYear }, (_, i) => startYear - i);

  const listNumberOfMonths = [1, 6, 12];

  const [monthsPage, setMonthsPage] = useState([]);
  const [monthUser, setMonthUser] = useState(formData.mes_ultima_fatura);
  const [lastYear, setLastYear] = useState(formData.ano_ultima_fatura);
  const [numberOfMonths, setNumberOfMonths] = useState(formData.numero_de_meses);

  const getMonthsPage = (monthUserInput, lastYearInput, numberOfMonthsInput=12) => {
    if (!monthUserInput || !lastYearInput || !numberOfMonthsInput) {
      return [];
    };

    lastYearInput = parseInt(lastYearInput);
    numberOfMonthsInput = parseInt(numberOfMonthsInput);
    var monthsPageChange = [];

    for (let i=0; i<=numberOfMonthsInput - 1; i++) {
      let inexMonthsPage = months.indexOf(monthUserInput) - i;

      let yearPage = inexMonthsPage >= 0 ? lastYearInput: lastYearInput - 1;
      let month = inexMonthsPage >= 0 ? months[inexMonthsPage]: months[numberOfMonthsInput + inexMonthsPage];

      monthsPageChange.push([month, yearPage]);
    };

    return monthsPageChange;
  };

  const handleFileChangeMonhtsInputs = (e) => {
    var { name, value } = e.target;

    const nameSetFunction = {
      "mes_ultima_fatura": setMonthUser,
      "ano_ultima_fatura": setLastYear,
      "numero_de_meses": setNumberOfMonths,
    };

    value =  ['numero_de_meses', 'ano_ultima_fatura'].includes(name) ? parseInt(value): value
  
    nameSetFunction[name](value);

    setFormData({
      ...formData,
      [name]: value
    });
  };

  useEffect(() => {
    setMonthsPage(
      getMonthsPage(monthUser, lastYear, numberOfMonths)
    );
  }, [monthUser, lastYear, numberOfMonths])

  // useEffect(() => {
  //   setMonthUser(formData.mes_ultima_fatura)
  //   setLastYear(formData.ano_ultima_fatura)
  //   setNumberOfMonths(formData.numero_de_meses)
  // }, [formData])

  return (
    <>
      <h3 style={{ marginTop: '1em' }} class='em-2'>
        Datos mensuales*
        <span
        data-toggle="tooltip"
        data-placement="top"
        title="Por favor, ingresa el último mes del cual tienes datos de facturas, junto con el año correspondiente. Después de completar esta información, puedes optar por proporcionar datos de 1, 6 o 12 meses. Recuerda que, cuanta más información proporciones, mejor será la calidad de los cálculos y los resultados obtenidos."
        >
          <i className="bi bi-info-circle-fill custom-tooltip"></i>
        </span>
      </h3>

      <label htmlFor="mes_ultima_fatura">
        Mes de la Última Factura*:
        <span
          data-toggle="tooltip"
          data-placement="top"
          title="Introduce el mes de la última factura que tienes."
        >
          <i className="bi bi-info-circle-fill custom-tooltip"></i>
        </span>
      </label>

      <select
        id="mes_ultima_fatura"
        name="mes_ultima_fatura"
        className="form-control energia-info"
        value={formData.mes_ultima_fatura}
        onChange={handleFileChangeMonhtsInputs}
        required
      >
        <option value='' hidden></option>
        {months.map((mes) => (
          <option value={mes}>{mes}</option>
        ))};
      </select>

      <label htmlFor="ano_ultima_fatura">
        Año de la Última Factura*:
        <span
          data-toggle="tooltip"
          data-placement="top"
          title="Introduce el ano de la última factura que tienes."
        >
          <i className="bi bi-info-circle-fill custom-tooltip"></i>
        </span>
      </label>

      <select
        id="ano_ultima_fatura"
        name="ano_ultima_fatura"
        className="form-control energia-info"
        value={formData.ano_ultima_fatura}
        onChange={handleFileChangeMonhtsInputs}
        required
      >
        <option value='' hidden></option>
        {years.map((mes) => (
          <option value={mes}>{mes}</option>
        ))};
      </select>

      <label htmlFor="numero_de_meses">
        Número de meses*:
        <span
          data-toggle="tooltip"
          data-placement="top"
          title="Puedes usar 1 mes, 6 meses o 12 meses dependiendo del número de meses que tengas."
        >
          <i className="bi bi-info-circle-fill custom-tooltip"></i>
        </span>
      </label>

      <select
        id="numero_de_meses"
        name="numero_de_meses"
        className="form-control energia-info"
        value={formData.numero_de_meses}
        onChange={handleFileChangeMonhtsInputs}
        required
      >
        <option value='' hidden></option>
        {listNumberOfMonths.map((mes) => (
          <option value={mes}>{mes} {mes == 1 ? 'Mes': 'Meses'}</option>
        ))};
      </select>

      <Months monthsPage={monthsPage} formData={formData} handleChange={handleChange} />
    </>
  );
};

const EnergiaInfo = ({ formData, setFormData, handleChange, handleFileChange, handlePreviousSection, handleNextSection }) => {
  useEffect(() => {
    const tooltips = document.querySelectorAll('[data-toggle="tooltip"]');
    tooltips.forEach((tooltip) => new Tooltip(tooltip));
  }, []);

  return (
    <section className="form-section active" id="section-energia">
      <h2>
        Energía
        <span
          data-toggle="tooltip"
          data-placement="top"
          title="Para completar la información de esta sección, simplemente necesitas tener a mano un recibo de luz. Para entender cómo leer la información de tu recibo de luz, visita este sitio informativo: https://tec-eos.com/sabes-como-leer-tu-recibo-de-cfe/"
        >
          <i className="bi bi-info-circle-fill custom-tooltip"></i> 
        </span>
      </h2>
      <label htmlFor="acceso_energia">
        Acceso a energía eléctrica de la red *:
      </label>
      <div className="form-check">
        <input
          className="form-check-input energia-info"
          type="radio"
          name="acceso_energia"
          id="acceso_energia_si"
          value="sí"
          checked={formData.acceso_energia === 'sí'}
          onChange={handleChange}
          // onChange={(e) => {changeMonthsPage('Abril', 2024)}}
          required
        />
        <label className="form-check-label" htmlFor="acceso_energia_si">Sí</label>
      </div>
      <div className="form-check">
        <input
          className="form-check-input energia-info"
          type="radio"
          name="acceso_energia"
          id="acceso_energia_no"
          value="no"
          checked={formData.acceso_energia === 'no'}
          onChange={handleChange}
          required
        />
        <label className="form-check-label" htmlFor="acceso_energia_no">No</label>
      </div>

      {formData.acceso_energia === 'sí' && (
        <>
          <h3>Datos generales</h3>
          <label htmlFor="voltaje">
            Voltaje:
          </label>
          <select
            id="voltaje"
            name="voltaje"
            className="form-control energia-info"
            value={formData.voltaje}
            onChange={handleChange}
          >
            <option value='' hidden></option>
            <option value="monofasico">Monofásico (120 a 127 V)</option>
            <option value="bifasico">Bifásico (220 V)</option>
            <option value="trifasico">Trifásico (220 V)</option>
            <option value="otro">Otro</option>
          </select>

          <label htmlFor="demanda_contratada">Demanda Contratada (kW):</label>
          <NumericInput
            id="demanda_contratada"
            name="demanda_contratada"
            className="form-control energia-info"
            value={formData.demanda_contratada}
            onChange={handleChange}
          />

          <label htmlFor="demanda_contratada">Número de Medidor:</label>
          <span
            data-toggle="tooltip"
            data-placement="top"
            title="Ingrese el número del medidor manualmente y/o inserte una foto."
          >
            <i className="bi bi-info-circle-fill custom-tooltip"></i>
          </span>
          <input
            id="numero_medidor"
            name="numero_medidor"
            className="form-control energia-info"
            value={formData.numero_medidor}
            onChange={handleChange}
          />

          <FileInput
            formData={formData}
            handleFileChange={handleFileChange}
            name={"foto_medidor"}
            id={"foto_medidor"}
            label={"Foto Medidor"}
          />

          {/* <DadosMensais formData={formData} setFormData={setFormData} handleChange={handleChange}/>

          <label style={{ marginTop: '2em' }}>¿Se ha pagado alguna multa por exceso de energía reactiva en los últimos 12 meses?</label>
          <div className="form-check">
            <input
              className="form-check-input"
              type="radio"
              name="multa_energia_reactiva"
              id="multa_si"
              value="sí"
              checked={formData.multa_energia_reactiva === 'sí'}
              onChange={handleChange}
            />
            <label className="form-check-label" htmlFor="multa_si">Sí</label>
          </div>
          <div className="form-check">
            <input
              className="form-check-input"
              type="radio"
              name="multa_energia_reactiva"
              id="multa_no"
              value="no"
              checked={formData.multa_energia_reactiva === 'no'}
              onChange={handleChange}
            />
            <label className="form-check-label" htmlFor="multa_no">No</label>
          </div>

          {formData.multa_energia_reactiva === 'sí' && (
            <>
              <label htmlFor="multa_mes">
                Mes:
              </label>
              <select
                id="multa_mes"
                name="multa_mes"
                className="form-control"
                value={formData.multa_mes}
                onChange={handleChange}
              >
                {['Enero', 'Febrero', 'Marzo', 'Abril', 'Mayo', 'Junio', 'Julio', 'Agosto', 'Septiembre', 'Octubre', 'Noviembre', 'Diciembre'].map((mes, index) => (
                  <option key={index} value={mes}>{mes}</option>
                ))}
              </select>

              <label htmlFor="multa_costo">
                Costo ($MXN):
              </label>
              <NumericInput
                id="multa_costo"
                name="multa_costo"
                className="form-control"
                value={formData.multa_costo}
                onChange={handleChange}
              />
            </>
          )} */}

        </>
      )}

      <div>
        <Button className="btn btn-info mt-2 text-white" variant="secondary" onClick={handlePreviousSection}>
          Anterior
        </Button>
        <Button className="btn btn-info mt-2 text-white" variant="primary" onClick={handleNextSection}>
          Próximo
        </Button>
      </div>
    </section>
  );
};

export default EnergiaInfo;
