import React, { useEffect, useState } from 'react';

const FilesInput = ({ formData, handleFileChange, name, id, label, buttonLabel }) => {
    // const [filenames, setFilenames] = useState(formData[name] ? formData[name] : []);

    const [filenames, setFilenames] = useState([]);

    useEffect(() => {
        // Ensure that formData[name] is an array before setting state
        if (formData[name] && Array.isArray(formData[name])) {
            setFilenames(formData[name]);
        } else if (formData[name] && typeof formData[name] === 'string') {
            // Handle case where formData[name] is a string (e.g., a single filename)
            setFilenames([formData[name]]);
        }
    }, [formData, name]);

    const handleFileChangeFinal = (event) => {
        handleFileChange(event);

        const files = Array.from(event.target.files); // Convert FileList to an array
        const filenamesArray = files.map(file => file.name);
        setFilenames(filenamesArray);
    };

    return (
        <div style={{ marginBottom: '1.6em' }}>
            <label htmlFor={id}>{label}:</label>
            <br /> {/* Line break added here */}
            <input
                type="file"
                id={id}
                name={name}
                multiple={true} // Enable multiple file selection
                style={{ display: 'none' }}
                onChange={handleFileChangeFinal}
            />
            <label htmlFor={id} className="custom-file-label-multiple w-100">
                {filenames.length > 0 && (
                    <ul>
                        {filenames.map((filename, index) => (
                            <li key={index}>{filename}</li>
                        ))}
                    </ul>
                )}
                {/* {filenames.length > 0 ? `Arquivos Selecionados: ${filenames.join(', \n')}` : 'Selecione um arquivo'} */}
            </label>
            {buttonLabel && (
                <button
                    type="button"
                    onClick={() => document.getElementById(id).click()}
                    className="btn btn-primary"
                >
                    {filenames.length > 0 ? `Change ${buttonLabel}` : `Choose ${buttonLabel}`}
                </button>
            )}
        </div>
    );
};

export default FilesInput;
