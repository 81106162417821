import React, { useEffect, useState } from 'react';
import { Button, Card, Nav } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap-icons/font/bootstrap-icons.css';
import Tooltip from 'bootstrap/js/dist/tooltip';
import NumericInput from '../NumericInput';
import IntegerInput from '../IntegerInput';
import FileInput from '../FileInput';

const NavigationCardTecho = ({ formData, setFormData, handleChange }) => {
  useEffect(() => {
    const tooltips = document.querySelectorAll('[data-toggle="tooltip"]');
    tooltips.forEach((tooltip) => new Tooltip(tooltip));
  }, []);

  const [activeTab, setActiveTab] = useState('first');

  const coordenadas = [
    {coordenada: "Noroeste", slug: "noroeste"},
    {coordenada: "Norte", slug: "norte"},
    {coordenada: "Nordeste", slug: "nordeste"},
    {coordenada: "Este", slug: "este"},
    {coordenada: "Sudeste", slug: "sudeste"},
    {coordenada: "Sur", slug: "sur"},
    {coordenada: "Sudoeste", slug: "sudoeste"},
    {coordenada: "Oeste", slug: "oeste"},
  ];

  const handleClick = (e, value) => {
    e.preventDefault();
    setActiveTab(value);
    // Add your logic here
    document.getElementById("tipo_area_techo").value = value;
    setFormData({
      ...formData,
      ["tipo_area_techo"]: value
    });
  };

  return (
    <Card style={{ width: '100%', marginTop: '2rem' }}>
      <Card.Header>
        <Nav variant="tabs" activeKey={activeTab} onSelect={setActiveTab}>
          <Nav.Item>
            <Nav.Link id='techo_first_nav_tab' eventKey="first" onClick={(e) => handleClick(e, 'first')}>Área Total sin sombra</Nav.Link>
          </Nav.Item>
          <Nav.Item>
            <Nav.Link id='techo_secound_nav_tab' eventKey="second" onClick={(e) => handleClick(e, 'second')}>Áreas de cada sección del techo</Nav.Link>
          </Nav.Item>
          {/* <Nav.Item>
            <Nav.Link href="#disabled" disabled>
              Disabled
            </Nav.Link>
          </Nav.Item> */}
          <input
            type="text"
            id="tipo_area_techo"
            name="tipo_area_techo"
            className="form-control techo-info"
            value={formData.tipo_area_techo}
            onChange={handleChange}
            hidden
          />
        </Nav>
      </Card.Header>
      <Card.Body>
        {activeTab === 'first' && (
          <>
            <Card.Title>
              Una opción es registrar el área total de todos los techos disponibles sin sombra para la instalación de paneles fotovoltaicos (m²)
            </Card.Title>
            <Card.Text>
              <label htmlFor="area_total_techos">
                Área total del techo disponible sin sombra (m²):
              </label>
            <NumericInput
              id="area_total_techos"
              name="area_total_techos"
              className="form-control techo-info"
              value={formData.area_total_techos}
              onChange={handleChange}
            />
            </Card.Text>
          </>
        )}
        {activeTab === 'second' && (
          <>
            <Card.Title>
              O, alternativamente, puede registrar las áreas de cada sección del techo de acuerdo con sus orientaciones *
              <span
                data-toggle="tooltip"
                data-placement="top"
                title="Para verificar la orientación del tejado, puedes usar una aplicación de brújula en tu celular o Google Maps. En la esquina inferior derecha del mapa de Google Maps, encontrarás un icono de brújula que te permite ajustar la vista para determinar la dirección del techo."
              >
                <i className="bi bi-info-circle-fill custom-tooltip"></i>
              </span>
              <span
                data-toggle="tooltip"
                data-placement="top"
                title="Se debe completar al menos uno!"
              >
                <i className="bi bi-info-circle-fill custom-tooltip"></i>
              </span>
            </Card.Title>
            <Card.Text>
              <img
                src={'/img/form/techo/orientaciones_techno.png'}
                alt={`Un ejemplo de las orientaciones que pueden tener los techos.`}
                className="img-max-width"
              />

              {coordenadas.map((item, index) => {
                return (
                  <>
                    <label htmlFor={`superficie_${item.slug}`}>
                      {item.coordenada} - Área Total Disponible (m²):
                    </label>
                    <NumericInput
                      id={`superficie_${item.slug}`}
                      name={`superficie_${item.slug}`}
                      value={formData[`superficie_${item.slug}`]}
                      onChange={handleChange}
                      className="form-control techo-info techo_coordinates"
                      />
                  </>
                )
            })}
            </Card.Text>
          </>
        )}
      </Card.Body>
    </Card>
  );
};

const TechoInfo = ({ formData, setFormData, handleChange, handleFileChange, handleNextSection, handlePreviousSection }) => {

  useEffect(() => {
    const tooltips = document.querySelectorAll('[data-toggle="tooltip"]');
    tooltips.forEach((tooltip) => new Tooltip(tooltip));
  }, []);

  useEffect(() => {
    const areasSecoundNavTab = document.getElementById('techo_secound_nav_tab');
    const areaFirstNavTab = document.getElementById('techo_first_nav_tab');

    if (formData.tipo_techo === 'techo_plano') {
      // Programmatically click the first tab
      areaFirstNavTab.click();

      setTimeout(() => {
        // setFormData({
        //   ...formData,
        //   [name]: value
        // });

        areasSecoundNavTab.setAttribute('disabled', true);
        areasSecoundNavTab.classList.add('disabled');
      }, 0);
    } else {
      // Enable the second tab if it's not 'techo_plano'
      areasSecoundNavTab.removeAttribute('disabled');
      areasSecoundNavTab.classList.remove('disabled');
    }
  }, [formData.tipo_techo]); // Only re-run the effect when 'tipoTecho' changes

  const handleChangeTipoTecho = (e) => {
    const { name, value } = e.target;

    // Update form data state
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  // // Exemplo com funcao, melhor com useEffect!
  // const handleChangeTipoTecho = (e) => {
  //   var { name, value } = e.target;

  //   setFormData({
  //     ...formData,
  //     [name]: value
  //   });

  //   const areasSecoundNavTab = document.getElementById('techo_secound_nav_tab');

  //   if (value === 'techo_plano') {
  //     const areaFirstNavTab = document.getElementById('techo_first_nav_tab');

  //     areaFirstNavTab.click()

  //     setTimeout(() => {
  //       setFormData({
  //         ...formData,
  //         [name]: value
  //       });

  //       areasSecoundNavTab.setAttribute('disabled', true);
  //       areasSecoundNavTab.classList.add('disabled');
  //     }, 0);
  //   } else {
  //     areasSecoundNavTab.removeAttribute('disabled');
  //     areasSecoundNavTab.classList.remove('disabled');
  //   };

  // };

  return (
    <section className="form-section active" id="section-techo">
      <h2>Techo</h2>
      
      <label htmlFor="tipo_techo">Tipo predominante de techo*:</label>
      <span
          data-toggle="tooltip"
          data-placement="top"
          title="
          Techo Inclinado: Caracterizado por tener una o más superficies inclinadas.
          "
        >
          <i className="bi bi-info-circle-fill custom-tooltip"></i>
      </span>
      <span
        data-toggle="tooltip"
        data-placement="top"
        title="
        Techo Plano: Posee una superficie prácticamente horizontal.
        "
      >
        <i className="bi bi-info-circle-fill custom-tooltip"></i>
      </span>

      <select
        id="tipo_techo"
        name="tipo_techo"
        className="form-control techo-info"
        value={formData.tipo_techo}
        onChange={handleChangeTipoTecho}
        required={true}
      >
        <option value='' hidden></option>
        {/* <option value="teja_metalica">Teja metálica</option>
        <option value="concreto_acero">Concreto/Acero</option>
        <option value="fibrocemento">Fibrocemento</option>
        <option value="asbesto">Asbesto</option>
        <option value="losa_plana">Cubierta de losa plana (sin inclinación)</option>
        <option value="teja_ceramica">Teja cerámica</option>
        <option value="lamina_carton">Lámina de cartón</option>
        <option value="otros">Otros</option> */}
        <option value='techo_inclinado'>Techo Inclinado </option>
        <option value='techo_plano'>Techo Plano</option>
      </select>

      <h4
      className="mt-4"
      >
        Existen dos formas de registrar la superficie no sombreada disponible para instalar paneles fotovoltaicos. Si no sabe el número exacto, haga una estimación.
        <span
          data-toggle="tooltip"
          data-placement="top"
          title="
          Para determinar la superficie disponible para paneles fotovoltaicos (m²), es necesario considerar el espacio libre y despejado donde los paneles puedan instalarse sin obstrucciones como sombras de árboles o edificios. 
          "
        >
          <i className="bi bi-info-circle-fill custom-tooltip"></i>
        </span>
        <span
          data-toggle="tooltip"
          data-placement="top"
          title="
          Las áreas sombreadas en techos son causadas por obstáculos como árboles, edificios, chimeneas y equipos de ventilación. Las sombras varían durante el día y el año, por lo tanto, es importante observar sombras en diferentes momentos y estaciones, principalmente al comienzo de la mañana y al final de la tarde, donde las sombras tienden a ser más largas.
          "
        >
          <i className="bi bi-info-circle-fill custom-tooltip"></i>
        </span>
      </h4>

      <NavigationCardTecho
        formData={formData}
        setFormData={setFormData}
        handleChange={handleChange}
      />

      <label>
        ¿Existe algún informe o verificación de la capacidad del techo para soportar un sistema fotovoltaico?
        <span
          data-toggle="tooltip"
          data-placement="top"
          title="Informe realizado por ingenieros especializados para determinar si el techo cuenta con la resistencia suficiente para soportar el peso de los paneles solares, los soportes de montaje y posibles cargas de viento."
        >
          <i className="bi bi-info-circle-fill custom-tooltip"></i>
        </span>
      </label>
      <div className="form-check">
        <input
          className="form-check-input"
          type="radio"
          name="informe_techo"
          id="informe_si"
          value="sí"
          checked={formData.informe_techo === 'sí'}
          onChange={handleChange}
        />
        <label className="form-check-label" htmlFor="informe_si">Sí</label>
      </div>
      <div className="form-check">
        <input
          className="form-check-input"
          type="radio"
          name="informe_techo"
          id="informe_no"
          value="no"
          checked={formData.informe_techo === 'no'}
          onChange={handleChange}
        />
        <label className="form-check-label" htmlFor="informe_no">No</label>
        {formData.informe_techo === 'no' && (
          <>
            <span
              data-toggle="tooltip"
              data-placement="top"
              title="
                Es importante realizar un laudo estructural del techo para evaluar las condiciones y garantizar que el techo puede soportar de manera segura la instalación de los paneles fotovoltaicos.
              "
            >
              <i className="bi bi-info-circle-fill custom-tooltip"></i>
            </span>
          </>
        )}
      </div>
      {formData.informe_techo === 'sí' && (
        <FileInput
          formData={formData}
          handleFileChange={handleFileChange}
          name={"anexos_informe"}
          id={"anexos_informe"}
          label={"Anexos Informe"}
        />
      )}

      <div>
        <Button className="btn btn-info mt-2 text-white" variant="secondary" onClick={handlePreviousSection}>
          Anterior
        </Button>
        <Button className="btn btn-info mt-2 text-white" variant="primary" onClick={handleNextSection}>
          Próximo
        </Button>
      </div>
    </section>
  );
};

export default TechoInfo;
