import React, { useEffect } from 'react';
import { Button, Row, Col, Accordion } from 'react-bootstrap';
import Tooltip from 'bootstrap/js/dist/tooltip';
import NumericInput from '../NumericInput';
import IntegerInput from '../IntegerInput';
import CardsEquipamentos from '../CardsEquipamentos';
import MultiselectSimple from '../MultiselectSimple';

import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap-icons/font/bootstrap-icons.css';

const AcordeonTuboFluorescente = ({ formData, handleChange, elementos }) => {
  return (
    <Accordion defaultActiveKey="0">
      {elementos.map((item, index) => {
        return (
          formData[item.form_data] === true && (
            <Accordion.Item eventKey={`${index}`}>
              <Accordion.Header>{item.label}</Accordion.Header>
              <Accordion.Body>
                <Row>
                  <Col xs={12} sm={12}>
                    <label htmlFor={`cantidad_${item.id}`}>Cantidad:</label>
                    <IntegerInput
                      id={`cantidad_${item.id}`}
                      name={`cantidad_${item.name}`}
                      className="form-control iluminacao-interna-info"
                      value={formData[`cantidad_${item.name}`]}
                      onChange={handleChange}
                    />
                  </Col>
              </Row>
            </Accordion.Body>
            </Accordion.Item>
          )
        );
      })}
    </Accordion>
  );
};

const TuboFluorescente = ({ formData, handleChange }) => {
  const luminarias = [
    {
      tipo: "T5",
      elementos: [
        {label: "Luminaria con 1 lámparas de tubo T5 de 14W", id: "luminaria_con_1_lamparas_de_tubo_t5_de_14_w", name: "luminaria_con_1_lamparas_de_tubo_t5_de_14_w", form_data: 'tubo_flourescente_t5'},
        {label: "Luminaria con 1 lámparas de tubo T5 de 28W", id: "luminaria_con_1_lamparas_de_tubo_t5_de_28_w", name: "luminaria_con_1_lamparas_de_tubo_t5_de_28_w", form_data: 'tubo_flourescente_t5'},
        {label: "Luminaria con 2 lámparas de tubo T5 de 14W", id: "luminaria_con_2_lamparas_de_tubo_t5_de_14_w", name: "luminaria_con_2_lamparas_de_tubo_t5_de_14_w", form_data: 'tubo_flourescente_t5'},
        {label: "Luminaria con 2 lámparas de tubo T5 de 28W", id: "luminaria_con_2_lamparas_de_tubo_t5_de_28_w", name: "luminaria_con_2_lamparas_de_tubo_t5_de_28_w", form_data: 'tubo_flourescente_t5'},
        {label: "Luminaria con 2 lámparas de tubo T5 de 54W", id: "luminaria_con_2_lamparas_de_tubo_t5_de_54_w", name: "luminaria_con_2_lamparas_de_tubo_t5_de_54_w", form_data: 'tubo_flourescente_t5'},
      ]
    },
    {
      tipo: "T8",
      elementos: [
        {label: "Luminaria con 1 lámparas de tubo T8 de 17W", id: "luminaria_con_1_lamparas_de_tubo_t8_de_17_w", name: "luminaria_con_1_lamparas_de_tubo_t8_de_17_w", form_data: 'tubo_flourescente_t8'},
        {label: "Luminaria con 1 lámparas de tubo T8 de 25W", id: "luminaria_con_1_lamparas_de_tubo_t8_de_25_w", name: "luminaria_con_1_lamparas_de_tubo_t8_de_25_w", form_data: 'tubo_flourescente_t8'},
        {label: "Luminaria con 1 lámparas de tubo T8 de 32W", id: "luminaria_con_1_lamparas_de_tubo_t8_de_32_w", name: "luminaria_con_1_lamparas_de_tubo_t8_de_32_w", form_data: 'tubo_flourescente_t8'},
        {label: "Luminaria con 2 lámparas de tubo T8 de 17W", id: "luminaria_con_2_lamparas_de_tubo_t8_de_17_w", name: "luminaria_con_2_lamparas_de_tubo_t8_de_17_w", form_data: 'tubo_flourescente_t8'},
        {label: "Luminaria con 2 lámparas de tubo T8 de 25W", id: "luminaria_con_2_lamparas_de_tubo_t8_de_25_w", name: "luminaria_con_2_lamparas_de_tubo_t8_de_25_w", form_data: 'tubo_flourescente_t8'},
        {label: "Luminaria con 2 lámparas de tubo T8 de 32W", id: "luminaria_con_2_lamparas_de_tubo_t8_de_32_w", name: "luminaria_con_2_lamparas_de_tubo_t8_de_32_w", form_data: 'tubo_flourescente_t8'},
      ]
    },
    {
      tipo: "T12",
      elementos: [
        {label: "Luminaria con 1 lámparas de tubo T12 de 21W", id: "luminaria_con_1_lamparas_de_tubo_t12_de_21_w", name: "luminaria_con_1_lamparas_de_tubo_t12_de_21_w", form_data: 'tubo_flourescente_t12'},
        {label: "Luminaria con 1 lámparas de tubo T12 de 39W", id: "luminaria_con_1_lamparas_de_tubo_t12_de_39_w", name: "luminaria_con_1_lamparas_de_tubo_t12_de_39_w", form_data: 'tubo_flourescente_t12'},
        {label: "Luminaria con 1 lámparas de tubo T12 de 55W", id: "luminaria_con_1_lamparas_de_tubo_t12_de_55_w", name: "luminaria_con_1_lamparas_de_tubo_t12_de_55_w", form_data: 'tubo_flourescente_t12'},
        {label: "Luminaria con 1 lámparas de tubo T12 de 75W", id: "luminaria_con_1_lamparas_de_tubo_t12_de_75_w", name: "luminaria_con_1_lamparas_de_tubo_t12_de_75_w", form_data: 'tubo_flourescente_t12'},
        {label: "Luminaria con 2 lámparas de tubo T12 de 21W", id: "luminaria_con_2_lamparas_de_tubo_t12_de_21_w", name: "luminaria_con_2_lamparas_de_tubo_t12_de_21_w", form_data: 'tubo_flourescente_t12'},
        {label: "Luminaria con 2 lámparas de tubo T12 de 39W", id: "luminaria_con_2_lamparas_de_tubo_t12_de_39_w", name: "luminaria_con_2_lamparas_de_tubo_t12_de_39_w", form_data: 'tubo_flourescente_t12'},
        {label: "Luminaria con 2 lámparas de tubo T12 de 55W", id: "luminaria_con_2_lamparas_de_tubo_t12_de_55_w", name: "luminaria_con_2_lamparas_de_tubo_t12_de_55_w", form_data: 'tubo_flourescente_t12'},
        {label: "Luminaria con 2 lámparas de tubo T12 de 75W", id: "luminaria_con_2_lamparas_de_tubo_t12_de_75_w", name: "luminaria_con_2_lamparas_de_tubo_t12_de_75_w", form_data: 'tubo_flourescente_t12'},
      ]
    },
  ];

  const itensTipos = luminarias.map((item) => {
    return {
      id: `tubo_flourescente_${item.tipo.toLowerCase()}`,
      name: `tubo_flourescente_${item.tipo.toLowerCase()}`,
      label: `Tipo ${item.tipo}`,
    }
  });

  return (
    <>
      <Row className='mt-4'>
        <Col xs={12} sm={12}>
          <h3>¿Ya tiene algun tubo fluorescente?</h3>
          {/* <span
            data-toggle="tooltip"
            data-placement="top"
            title='
            Para determinar la potencia instalada del sistema fotovoltaico, simplemente multiplica la potencia de cada panel fotovoltaico por la cantidad total de 
            paneles instalados. Por ejemplo, si cada panel tiene una potencia de 500 W y hay 12 paneles instalados, la potencia instalada sería 500 W × 12 paneles = 6000 W, o 6 kW, donde "k" representa mil.'
          >
            <i className="bi bi-info-circle-fill custom-tooltip"></i>
          </span> */}
        </Col>
      </Row>
      <Row>
        <Col xs={12} sm={12} className="text-center d-flex flex-column align-items-center">
          <img src="/img/form/ilumination/flourecente.png" alt="Tubo Flourescente" className="" />
          <p className='text-img'>Exjemplo de Tubo Flourescente</p>
        </Col>
      </Row>
      <Row>
        <Col xs={12} sm={12}>
          <div className="form-check">
            <input
              className="form-check-input"
              type="radio"
              name="tubo_flourescente"
              id="tubo_flourescente_si"
              value="sí"
              checked={formData.tubo_flourescente === 'sí'}
              onChange={handleChange}
            />
            <label className="form-check-label" htmlFor="tubo_flourescente_si">Sí</label>
          </div>
          <div className="form-check">
            <input
              className="form-check-input"
              type="radio"
              name="tubo_flourescente"
              id="tubo_flourescente_no"
              value="no"
              checked={formData.tubo_flourescente === 'no'}
              onChange={handleChange}
            />
            <label className="form-check-label" htmlFor="tubo_flourescente_no">No</label>
          </div>
        </Col>
      </Row>

      {formData.tubo_flourescente === 'sí' && (
        <>
          <Row>
            <Col>
              <MultiselectSimple formData={formData} handleChange={handleChange} itens={itensTipos} label="Tipo de Tubos Flourescentes"/>
            </Col>
          </Row>
          <Row className='mt-2'>
            <Col xs={12} sm={12}>

              {formData.tubo_flourescente_t5 && (
                <>
                  <label>Tipo T5</label>
                  <AcordeonTuboFluorescente formData={formData} handleChange={handleChange} elementos={luminarias[0].elementos}/>
                </>
              )}
              {formData.tubo_flourescente_t8 && (
                <>
                  <label>Tipo T8</label>
                  <AcordeonTuboFluorescente formData={formData} handleChange={handleChange} elementos={luminarias[1].elementos}/>
                </>
              )}
              {formData.tubo_flourescente_t12 && (
                <>
                  <label>Tipo T12</label>
                  <AcordeonTuboFluorescente formData={formData} handleChange={handleChange} elementos={luminarias[2].elementos}/>
                </>
              )}

            </Col>
          </Row>
        </>
      )}
    </>
  )
};


const IluminationInternaInfo = ({ formData, setFormData, handleChange, handlePreviousSection, handleNextSection }) => {
  useEffect(() => {
    const tooltips = document.querySelectorAll('[data-toggle="tooltip"]');
    tooltips.forEach((tooltip) => new Tooltip(tooltip));
  }, []);

  const technologies = [
    {id: 'bombilla_led', name: 'bombilla_led', img: '/img/form/ilumination/bobina_led.png', label: 'Bombilla LED', inputs: true},
    {id: 'incandescente', name: 'incandescente', img: '/img/form/ilumination/incandescente.png', label: 'Incandescente', inputs: true},
    {id: 'led_tubular', name: 'led_tubular', img: '/img/form/ilumination/led_tubular.jpg', label: 'Led Tubular', inputs: true},
    {id: 'fluorescente_compacta', name: 'fluorescente_compacta', img: '/img/form/ilumination/flourescente_compacta.png', label: 'Fluorescente compacta', inputs: true},
    {id: 'halogeno_dicrona', name: 'halogeno_dicrona', img: '/img/form/ilumination/halogeno.png', label: 'Lámpada Halógena Dicrona', inputs: true},
    {id: 'halogeno_tubo', name: 'halogeno_tubo', img: '/img/form/ilumination/halogena_tipo_tubo.png', label: 'Lámpara Halógena Tipo Tubo', inputs: true},
    {id: 'panel_led', name: 'panel_led', img: '/img/form/ilumination/painel_led.png', label: 'Panel LED', inputs: true},

    // {id: 'tubo_led', name: 'tubo_led', img: '/img/form/ilumination/tubo_led.png', label: 'Tubo LED', inputs: false},
    // { tipo: 'Tipo T5', id: 'tubo_led_t5', name: 'tubo_led_t5',  img: '/img/form/ilumination/tubo_led.png', label: 'Tubo LED T5', inputs: true},
    // { tipo: 'Tipo T8', id: 'tubo_led_t8', name: 'tubo_led_t8',  img: '/img/form/ilumination/tubo_led.png', label: 'Tubo LED T8', inputs: true},
    // { tipo: 'Tipo T12', id: 'tubo_led_t12', name: 'tubo_led_t12',  img: '/img/form/ilumination/tubo_led.png', label: 'Tubo LED T12', inputs: true},


    // {id: 'tubo_fluorescente', name: 'tubo_fluorescente', img: '/img/form/ilumination/flourecente.png', label: 'Tubo Fluorescente', inputs: false},
    // { tipo: 'Tipo T5',  id: 'fluorescente_t5_lineal', name: 'fluorescente_t5_lineal', inputs: true},
    // { tipo: 'Tipo T8',  id: 'fluorescente_t8_lineal', name: 'fluorescente_t8_lineal', inputs: true},
    // { tipo: 'Tipo T12',  id: 'fluorescente_t12_lineal', name: 'fluorescente_t12_lineal', inputs: true},
    
    // { tipo: 'Fluorescente U', id: 'fluorescente_u', name: 'fluorescente_u', label: 'Ejemplo de Fluorescente U', inputs: true},
  ];

  return (
    <section className="form-section active" id="section-iluminacion">
      <h2>Iluminación Interna</h2>

      <label htmlFor={`horas_dia_acendida_interior`}>En promedio, ¿cuántas horas al día está encendida la iluminación interior?:</label>
      <NumericInput
        id='horas_dia_acendida_interior'
        name={`horas_dia_acendida_interior`}
        className="form-control iluminacion-info"
        value={formData.horas_dia_acendida_interior}
        onChange={handleChange}
      />

      <div style={{ marginTop: '1.5em' }}></div>

      <CardsEquipamentos formData={formData} setFormData={setFormData} handleChange={handleChange} equipamentos={technologies} />

      <TuboFluorescente formData={formData} handleChange={handleChange} />

      <div className="navigation-buttons" style={{ marginTop: '1.5em' }}>
        <Button className="btn btn-info mt-2 text-white" variant="secondary" onClick={handlePreviousSection}>
          Anterior
        </Button>
        <Button className="btn btn-info mt-2 text-white" variant="primary" onClick={handleNextSection}>
          Próximo
        </Button>
      </div>
    </section>
  );
};

export default IluminationInternaInfo;
