import React, { useEffect, useState } from 'react';
import { Button } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap-icons/font/bootstrap-icons.css';
import Tooltip from 'bootstrap/js/dist/tooltip';
import NumericInput from '../NumericInput';
import IntegerInput from '../IntegerInput';
import FileInput from '../FileInput';
import FilesInput from '../FilesInput';

const CaracteristicasArquitetonicas = (
  { formData, handleChange, handleFileChange, handleMultipleFileChange, handleNextSection, handlePreviousSection }
) => {
  useEffect(() => {
    const tooltips = document.querySelectorAll('[data-toggle="tooltip"]');
    tooltips.forEach((tooltip) => new Tooltip(tooltip));
  }, []);

  return (
    <section className="form-section active" id="section-techo">
      <h2>Características arquitectonicas</h2>

      <label>
        ¿Existe información organizada disponible sobre metros cuadrados, proyectos arquitectónicos, materiales de construcción, entre otros?
      </label>
      {/* <span
        data-toggle="tooltip"
        data-placement="top"
        title="¿Existe información organizada disponible sobre metros cuadrados, proyectos arquitectónicos, materiales de construcción, entre otros?"
      >
        <i className="bi bi-info-circle-fill custom-tooltip"></i>
      </span> */}
      <div className="form-check">
        <input
          className="form-check-input"
          type="radio"
          name="informacion_organizada"
          id="informacion_organizada_si"
          value="sí"
          checked={formData.informacion_organizada === 'sí'}
          onChange={handleChange}
        />
        <label className="form-check-label" htmlFor="informe_si">Sí</label>
      </div>
      <div className="form-check">
        <input
          className="form-check-input"
          type="radio"
          name="informacion_organizada"
          id="informacion_organizada_no"
          value="no"
          checked={formData.informacion_organizada === 'no'}
          onChange={handleChange}
        />
        <label className="form-check-label" htmlFor="informe_no">No</label>
      </div>

      {formData.informacion_organizada === 'sí' && (
        <>
          <label>
            Enlace de acceso:
          </label>
          <span
            data-toggle="tooltip"
            data-placement="top"
            title="Aquí puedes colocar el enlace de acceso a los archivos o adjuntar los archivos."
          >
            <i className="bi bi-info-circle-fill custom-tooltip"></i>
          </span>

          <input
            type="text"
            id="enlance_de_acceso"
            name="enlance_de_acceso"
            className="form-control general-info mb-2"
            value={formData.enlance_de_acceso}
            onChange={handleChange}
            required
          />
        
        <FileInput
          formData={formData}
          handleFileChange={handleFileChange}
          name={"anexos_informacion_organizada"}
          id={"anexos_informacion_organizada"}
          label={"Anexos Informacion Organizada"}
        />

          {/* <div>
              <input
                  type="file"
                  id="anexos_informacion_organizada"
                  name="anexos_informacion_organizada"
                  className="form-control-file"
                  style={{ display: 'none' }}
                  onChange={handleFileChange}
              />
              {filenameAnexos && <p>Arquivo Selecionado: {filenameAnexos}</p>}
          </div> */}
        </>
      )}

      <label htmlFor="edad_aproximada">
        Edad aproximada del inmueble. ¿Cuántos años han transcurrido desde la construcción o la última renovación importante? Si no sabe el número exacto, haga una estimación:
      </label>
      <NumericInput
        id="edad_aproximada"
        name="edad_aproximada"
        value={formData.edad_aproximada}
        onChange={handleChange}
      />

      <label htmlFor="numero_de_pisos">
        Número de pisos:
      </label>
      <span
        data-toggle="tooltip"
        data-placement="top"
        title="Los números de pisos se refieren a la numeración usada para identificar los niveles de un edificio, incluyendo sótanos."
      >
        <i className="bi bi-info-circle-fill custom-tooltip"></i>
      </span>
      <NumericInput
        id="numero_de_pisos"
        name="numero_de_pisos"
        value={formData.numero_de_pisos}
        onChange={handleChange}
      />

      <label htmlFor="superficie_construida">
        Superficie construida (m²)*:
      </label>
      <span
        data-toggle="tooltip"
        data-placement="top"
        title=" Área aproximada (metraje) del edificio. Si no sabe el número exacto, haga una estimación.*"
      >
        <i className="bi bi-info-circle-fill custom-tooltip"></i>
      </span>
      <NumericInput
        className="caracteristicas_arquitetonicas"
        id="superficie_construida"
        name="superficie_construida"
        value={formData.superficie_construida}
        onChange={handleChange}
        required={true}
      />

      <FilesInput
        formData={formData}
        handleFileChange={handleFileChange}
        name={"fotos"}
        id={"fotos"}
        label={"Fotos (Áreas externas, internas y techo)"}
      />

      <div>
        <Button className="btn btn-info mt-2 text-white" variant="secondary" onClick={handlePreviousSection}>
          Anterior
        </Button>
        <Button className="btn btn-info mt-2 text-white" variant="primary" onClick={handleNextSection}>
          Próximo
        </Button>
      </div>
    </section>
  );
};

export default CaracteristicasArquitetonicas;
